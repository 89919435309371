
<template>

  <div class="wrapper">
    <div class="animated fadeIn">
      <!-- 판매관리 --------------------------------------------------------------------------------------------->
      <BCard>
        <BCard class="mb-1" footer-tag="footer" header-tag="header">

          <div slot="header">
            <BIconGiftFill/>
            <strong> 판매원장 </strong>
            <div class="card-header-actions">
              <small class="text-muted">주문을 생성, 승인 관리 및 승인된 주문에 대하여 인증서를 전송합니다.</small>
            </div>
          </div>

          <BRow class="mb-2">
            <BCol cols="2">
              <!--                            <strong>Total Records: {{ rows.length }}</strong>-->
              <BInputGroup>
                <BFormSelect v-model="selectedPrd"
                             :options="prdOpts"
                             size="sm"/>
                <BInputGroupAppend>
                  <BButton size="sm" variant="warning" @click="createSalesOrder">
                    <BIconPlusCircleFill/>
                    주문생성
                  </BButton>
                </BInputGroupAppend>
              </BInputGroup>
            </BCol>

            <BCol>
              <BInputGroup size="sm">
                <BFormSelect v-model="dateGbn"
                             :options="dateGbnOpt"
                             size="sm"
                             @input="setDateGbn"/>

                <BInputGroupAppend>
                  <BFormInput v-model="fromDate"
                              v-b-tooltip.hover
                              size="sm"
                              title="Enter 검색" type="date"
                              @change="getSalesOrderList"/>

                </BInputGroupAppend>
                <BInputGroupAppend>
                  <BFormInput v-model="toDate"
                              v-b-tooltip.hover
                              size="sm"
                              title="Enter 검색" type="date"
                              @change="getSalesOrderList"/>
                </BInputGroupAppend>
              </BInputGroup>
            </BCol>
            <BCol>
              <BInputGroup>
                <BFormSelect v-model="searchField"
                             :options="searchOpts"
                             size="sm"/>
                <BInputGroupAppend>
                  <BFormInput v-model="searchWord"
                              v-b-tooltip.hover
                              size="sm"
                              title="Enter 검색" type="text"
                              @keyup.enter="getSalesOrderList"/>
                </BInputGroupAppend>
                <BInputGroupAppend>
                  <BButton size="sm" variant="primary"
                           @click="getSalesOrderList">검 색</BButton>
                </BInputGroupAppend>

              </BInputGroup>

            </BCol>
            <BCol cols="2">
              <BInputGroup>
                <BFormSelect v-model="perPage" :options="[10,20,30,50,100,200]" size="sm"/>
                <BInputGroupAppend>
                  <BButtonGroup>
                    <BButton size="sm" variant="primary" @click="getSalesOrderList">
                      <BIconArrowRepeat/>
                    </BButton>
                    <BButton class="ml-1" size="sm" variant="info" @click="exportExcel">
                      <BIconFileSpreadsheetFill/>
                      xlsx
                    </BButton>
                  </BButtonGroup>
                </BInputGroupAppend>
              </BInputGroup>

            </BCol>

          </BRow>

          <BRow>
            <BCol>
              <vue-excel-editor
                  ref="orderGrid"
                  v-model="rows"
                  :localized-label="$localizedLabel"
                  :page="perPage"
                  :readonly-style="{backgroundColor:'#EFE'}"
                  class="mb-1"
                  filter-row
                  width="100%"
                  @select="selectSalesOrder"
                  @update="updateSalesOrder">
                <vue-excel-column field="_id" label="주문번호" readonly type="string" width="80px" :to-text="v=>{return v?v.slice(-6).toUpperCase():'N/A'}"/>
                <vue-excel-column field="userId" label="주문자ID" width="110px"/>
                <vue-excel-column field="loginId" label="이용자ID" width="110px"/>
                <vue-excel-column field="createdAt" label="등록일" type="datetime" width="140px" :to-text="toYmdHm" readonly/>
                <vue-excel-column field="name" label="성명" width="80px"/>
                <vue-excel-column field="salesMaster" label="상품명" width="200px" :to-text="v=>{ return v? v.title:''}"/>
                <vue-excel-column field="products" label="주문제품" width="200px" :to-text="v=>{ return v.length?v.map(i=>{return i.prd_name}):''}" readonly/>
                <vue-excel-column field="amount" label="금액" readonly type="number" width="80px" :to-text="toComma"/>
                <vue-excel-column field="payAmount" label="결제액" type="number" width="80px" :to-text="toComma" :to-value="toNumber"/>
                <vue-excel-column :options="payStatOpts" field="payStat" label="지불상태" readonly type="map" width="80px"/>
                <vue-excel-column :options="payTypeOpts" field="payType" label="결제" type="map" width="140px"/>
                <vue-excel-column field="depositor" label="입금자"  width="100px"/>
                <vue-excel-column field="phoneNo" label="연락처" width="130px"/>
                <vue-excel-column field="email" label="Email" width="170px"/>
                <vue-excel-column field="compName" label="회사명" width="100px"/>
                <vue-excel-column field="prName" label="대표자명" width="90px"/>
                <vue-excel-column field="address" label="사업장주소" width="130px"/>
                <vue-excel-column field="bizKind" label="업태/업종" width="110px"/>
                <vue-excel-column field="compNo" label="사업자번호" width="110px"/>
                <vue-excel-column field="pgTran" label="TR_ID" readonly type="number" width="60px" />
                <vue-excel-column field="payDt" label="결제일" type="date" width="88px" readonly :to-text="toYmdHm"/>
                <vue-excel-column field="approveYn" label="승인" type="checkYN" width="50px"/>
                <vue-excel-column field="approveDt" label="승인일시" readonly type="datetime" width="140px"/>
                <vue-excel-column field="cancelYn" label="취소" readonly type="checkYN" width="40px"/>
                <vue-excel-column field="cancelDt" label="취소일" readonly type="date" width="90px"/>
                <vue-excel-column field="comment" label="비고" width="140px"/>
                <vue-excel-column field="regId" label="등록자" readonly width="95px"/>
                <vue-excel-column field="createdAt" label="등록일" readonly type="date" width="95px"/>
                <vue-excel-column field="updId" label="수정자" readonly width="95px"/>
                <vue-excel-column field="updatedAt" label="수정일" readonly type="date" width="95px"/>
              </vue-excel-editor>
            </BCol>
          </BRow>

          <BRow>
            <BCol>
              <BButtonGroup v-show="order!==null">
                <BButton variant="warning" @click="approvalSalesOrder">
                  <BIconCartPlusFill/>
                  주문승인
                </BButton>
                <BButton class="ml-1" variant="primary" @click="sendDocument">
                  <BIconJournalMedical/>
                  인증서 전송
                </BButton>
                <BButton class="ml-1" variant="danger" @click="deleteSalesOrder">
                  <BIconTrashFill/>
                  주문삭제
                </BButton>
              </BButtonGroup>
            </BCol>

            <BCol>
              <BProgress :max="totalCount" :value="progCount" height="2rem" show-value variant="warning"/>
            </BCol>
          </BRow>
        </BCard>
        <BCard>
          <div slot="header">
            <BIconGiftFill/>
            <strong> 주문상세 내용 </strong>
            <div class="card-header-actions">
              <small class="text-muted">주문을 생성, 승인 관리 및 승인된 주문에 대하여 인증서를 전송합니다.</small>
            </div>
          </div>

          <BRow>
            <BCol sm="6">
              <BTable v-if="order!==null" small stacked :items="[order]" :fields="ordFields">
                <template #cell(products)>
                  <BTable hover responsive striped
                    class="small"
                    style="min-width:140%;"
                    :items="order.products"
                    :fields="prdFields"
                    thead-class="text-center"
                    ref="productTable"
                    id="productTable"
                  >
                    <template #cell(apprBtn)="{item}">
                      <BButton v-show="item.confirmYn==='N'" size="sm" variant="info" @click="licenseConfirm(item)">승인</BButton>
                      <BButton v-show="item.confirmYn==='Y'" size="sm" variant="danger" @click="licenseCancel(item)">취소</BButton>
                    </template>
                    <template #cell(sendBtn)="{item}">
                      <BButton size="sm" variant="info" @click="licenseEmail(item)" :disabled="item.confirmYn==='N'||isSending===true">
                        <CSpinner size="sm" type="grow" v-if="isSending"></CSpinner> 전송
                      </BButton>
                    </template>
                  </BTable>
                </template>
              </BTable>
            </BCol>
            <BCol sm="6" v-if="order!==null">
              <BButton class="text-right" variant="info" @click="getUserLicenses">
                [<b>{{order.userId}}</b>] 라이센스 정보 조회 <BIconArrowClockwise/>
              </BButton>
              <BTable small sticky-header responsive hober striped selectable
                      select-mode="single"
                      class="small text-nowrap mt-2"
                      @row-selected="licenseSelected"
                      :items="licenses"
                      :fields="licenseFields" >
              </BTable>

              <BCard v-if="license">
                <div slot="header">
                  <BIconArchiveFill/>
                  <strong> [{{license.level}}] 만료일 수정</strong>
                  <div class="card-header-actions">
                    {{license.prdName}}
                  </div>
                </div>
                <BRow>
                  <BCol cols="9">
                    <BInputGroup size="sm" prepend="라이센스">
                      <BFormInput size="sm" type="date" v-model="license.lvEnd"/>
                      <BInputGroupAppend size="sm">
                        <BInputGroupText>추가일</BInputGroupText>
                      </BInputGroupAppend>
                      <BFormInput class="small" size="sm" type="number" v-model="addLvDay" @input="addDays('lv')"/>
                    </BInputGroup>

                    <BInputGroup size="sm" prepend="유지보수" class="mt-1">
                      <BFormInput size="sm" type="date" v-model="license.maEnd"/>
                      <BInputGroupAppend size="sm">
                        <BInputGroupText>추가일</BInputGroupText>
                      </BInputGroupAppend>
                      <BFormInput class="small" size="sm" type="number" v-model="addMaDay" @input="addDays('ma')"/>
                    </BInputGroup>
                  </BCol>

                  <BCol>
                    <BButton size="sm"
                             variant="warning"
                             @click="setUserLicense( 'update')">
                      <BIconArrowUpCircleFill/> 수 정
                    </BButton>
                    <br/>
                    <BButton size="sm" class="mt-1"
                             variant="danger"
                             @click="setUserLicense( 'delete')">
                      <BIconTrash2Fill/> 삭 제
                    </BButton>
                  </BCol>
                </BRow>
                <BRow class="mt-2">
                  <BCol>
                    <BInputGroup size="sm" prepend="날짜계산">
                      <BFormInput class="small" type="date" size="sm" v-model="dt1" @input="diffDay"/>
                      <BInputGroupAppend size="sm">
                        <BInputGroupText>-</BInputGroupText>
                      </BInputGroupAppend>
                      <BFormInput class="small" type="date" size="sm" v-model="dt2" @input="diffDay"/>
                      <BInputGroupAppend size="sm">
                        <BInputGroupText>=</BInputGroupText>
                      </BInputGroupAppend>
                      <BFormInput class="small" size="sm" v-model="diffDt" readonly/>
                    </BInputGroup>
                  </BCol>
                </BRow>

              </BCard>

              <BButton class="text-right mt-2" variant="info" @click="getPaymentList">
                [<b>{{order._id.toUpperCase().slice(-6)}}</b>] 결제 내역 조회 <BIconArrowClockwise/>
              </BButton>
              <BTable small sticky-header selectable hover striped responsive bordered
                      select-mode="single"
                      class="mt-2 text-nowrap small"
                      :items="payments"
                      :fields="paymentFields"
                      @row-selected="paymentSelected">
                <template #cell(cancelYn)="{item}">
                  <BBadge class="small" v-show="item.cancelYn==='Y'" variant="danger">취소완료</BBadge>
                </template>


              </BTable>

              <BInputGroup v-if="payment!==null" :prepend="`결제번호: ${payment._id.slice(-6).toUpperCase()}`" >
                <BFormInput v-model="payment.cancelMSG"/>
                <BButton variant='danger' v-if="payment!==null" @click="paymentCancel">
                   결제 취소
                  <BSpinner small type="grow" v-if="isCancelProc"/>
                </BButton>
              </BInputGroup>


            </BCol>
          </BRow>

        </BCard>


      </BCard>

    </div>
  </div>
</template>






<script>

//-------------------------------------------------------------------------------------------------
import {
  alertSync,
  alertConfirm,
  apiCall,
  cloneVar,
  checkBisNo,
  toastSync,
  alertWarn,
  commify,
  numberfy, sleep
} from '../../../common/utils';
import qs from 'querystring';
import moment from "moment";
// import '../../../common/HelperMixin';


const _sales_order = {
    userId       : '', // 구매자 ID
    userNo       : '',
    salesMaster  : null,
    xcostUser    : null,
    xcostPayment : null,
    pgTran       : null,
    orderType    : 'GENERAL', // 일반,업그레이드 UPGRADE
    loginId      : '', // 이용자 ID
    products     : [],
    amount         : 0, // *구매 총액(할인율적용)
    orderToken     : null, // 구매 토큰
    payType        : '0000', // 결제대기
    payStat        : '99', // 결제 상태 : 99-미결제, 00-결제완료
    payAmount      : 0, // 결제금액
    payDt          : null, // 결제일시
    trsNo          : 0,
    email          : '',
    name           : '', // 구매자명
    prName         : '', // 대표자명
    phoneNo        : '', // 구매자 연락처
    depositor      : '', // 입금자명
    address        : '',
    zipCode        : '',
    taxStat        : '03', // 사업자상태 '01':'사업자', '03':'개인', '04':'영수증발행대상'
    compNo         : '',
    compName       : '',
    bizKind        : '', // 업종 업태
    cancelYn       : 'N' ,
    cancelDt       : null,
    cancelDesc     : '',
    approveYn      : 'N', // 주문승인
    approveDt      : null, // 주문승인일시
    description    : '',
    comment        : '',
    remark         : '', // 리마크
    updId          : '',
    regId          : ''
};

const payTypeOpts =  {
  '9999': '[9999] 주문생성',
  '0000': '[0000] 결제대기',
  '1000': '[1000] 현금(계좌)',
  '0001': '[0001] 쿠폰',
  '0002': '[0002] 바우처',
  '0010': '[0010] 포인트',
  '0011': '[0011] 포인트|쿠폰',
  '0100': '[0100] 카드',
  '0101': '[0101] 카드|쿠폰',
  '0110': '[0110] 카드|포인트',
  '0111': '[0111] 카드|포인트|쿠폰',
  '2000': '[2000] 후불',
  '1001': '[1001] 현금|쿠폰',
  '1010': '[1010] 현금|포인트',
  '1011': '[1011] 현금|포인트|쿠폰',
  '1100': '[1100] 현금|카드',
  '1101': '[1101] 현금|카드|쿠폰',
  '1110': '[1110] 현금|카드|포인트',
  '1111': '[1111] 현금|카드|포인트|쿠폰',
  }


//----------------------------------------------------------------------------------------------------
export default {
  name: 'SalesOrder',
  data() {
    return {
      isSending: false,
      perPage: 30,
      prdCodeOpts: {},
      selectedPrd: null,
      prdOpts: [],
      prdInfo: {},
      searchField: 'userId',
      searchOpts: [
        {value: 'userId', text: '아이디'},
        {value: 'name', text: '이름'},
        {value: 'compName', text: '회사명'},
        {value: 'compNo', text: '사업자번호'},
        {value: 'loginId', text: '이용자아이디'},
        {value: 'email', text: '전자우편'},
        {value: 'phoneNo', text: '전화번호'},
        {value: 'prdCode', text: '제품코드'}
      ],
      dayOpts: [
        {value: 7, text: '1주일'},
        {value: 14, text: '2주일'},
        {value: 30, text: '30일'},
        {value: 90, text: '90일'},
        {value: 180, text: '180일'},
        {value: null, text: '전체'},
      ],
      dateGbn: "reg",
      dateGbnOpt: [
          {value: 'reg', text: '등록일'}
        // , {value: 'ord', text: '주문일'}
        // , {value: 'appr', text: '승인일'}
        // , {value: 'pay', text: '결제일'}
        // , {value: 'send', text: '전송일'}
        // , {value: 'tax', text: '계산서발행일'}
        // , {value: 'upd', text: '수정일'}
      ],
      payTypeOpts,
      taxStatOpts: {
        '99': '미발행',
        '01': '영수계산서대상',
        '02': '청구계산서대상',
        '03': '현금영수증대상',
        '04': '영수증발행대상',
        '11': '영수발행',
        '12': '청구발행',
        '13': '현금영수증발행',
        '14': '영수증발행'
      },
      payStatOpts: {'99': '미결제', '10': '부분결제', '20': '초과결제', '00': '결제완료', '09':'결제취소', '19':'부분결제취소', '29':'초과결제취소'},
      sendStatOpts: {'99': '미발송', '10': '구매확인', '20': '발송준비', '90': '발송에러', '00': '발송완료'},

      statusOpt: {'00': '활성', '10': '준비', '90': '추출', '99': '만료'},
      logTypeOpts: {'I': '접속', 'O': '종료', 'S': '설치'},
      progCount: 0,
      totalCount: 0,

      rows: [],
      claimLogs: [],
      selectedIdx: null,
      selectedRow: null,
      fromDate: moment().format("YYYY-MM-01"),
      toDate: moment(this.fromDate).endOf("month").format("YYYY-MM-DD"),

      // fromDate: moment().subtract( 7,"days").format('YYYY-MM-DD'),
      // toDate: moment().format('YYYY-MM-DD'),
      /////////////////////////////////////////////////////
      searchWord: null,
      paramMap: {},
      dt1: moment().format('YYYY-MM-DD'),
      dt2: moment().format('YYYY-MM-DD'),
      diffDt: 0,
      order: null,
      ordFields: [
        { key: '_id'          , label: '주문번호', tdClass:'text-danger', formatter: v=>{return v.toUpperCase().slice(-6)} },
        { key: 'createdAt'    , label: '주문일시', formatter: v=>{return moment(v).format('YYYY-MM-DD HH:mm')}},
        { key: 'salesMaster'  , label: '상품정보', formatter: v=>{return v? `[${v.code}] ${v.name} | ${v.title}`: '' }},
        { key: 'products'     , label: '주문제품'},
        { key: 'amount'       , label: '주문금액', formatter: v=>{ return v? commify(v):''}},
        { key: 'userId'       , label: '아이디'},
        { key: 'pgTran'       , label: '결제정보'},
        { key: 'payType'      , label: '결제방식'},
        { key: 'payStat'      , label: '결제상태'},
        { key: 'payAmount'    , label: '결제금액', formatter: v=>{ return v? commify(v):''}},
        { key: 'approveYn'    , label: '승인'},
        { key: 'approveDt'    , label: '승인일시'},
        { key: 'email'        , label: '이메일'},
        { key: 'name'         , label: '주문자명'},
        { key: 'compName'     , label: '회사명'},
        { key: 'prName'       , label: '대표자명'},
        { key: 'bizKind'      , label: '업종'},
        { key: 'compNo'       , label: '사업자번호'},
        { key: 'taxStat'      , label: '계산서구분'},
        { key: 'description'  , label: '요청내용'},
        { key: 'cancelYn'     , label: '취소여부'},
        { key: 'cancelDt'     , label: '취소일시'},
        { key: 'cancelDesc'   , label: '취소사유'},
        // { key: 'comment'      , label: '취소사유'},
        { key: 'button'       , label: '주문승인'},
      ],
      prdFields: [
        { key: 'prd_code'     , label: '제품코드', tdClass: 'text-nowrap'},
        { key: 'disp_name'    , label: '제품명', tdClass: 'text-nowrap' },
        { key: 'unit_price'   , label: '금액(원가)', tdClass:'text-right',  formatter: v=>{ return v? commify(v):''} },
        { key: 'discount_price', label: '할인금액', tdClass:'text-right',  formatter: v=>{ return v? commify(v):'0'} },
        // { key: 'qty'          , label: '수량'},
        { key: 'warranty_mon' , label: '사용(월)'},
        { key: 'confirmYn'    , label: '승인'},
        { key: 'confirmDt'    , label: '승인일시', tdClass: 'text-nowrap', formatter: v=>{ return v? this.toYmdHm(v):''}},
        // { key: 'userLicense'  , label: '라이센스ID'},
        { key: 'apprBtn'      , label: '승인', tdClass: 'text-nowrap'},
        { key: 'sendBtn'      , label: '메일', tdClass: 'text-nowrap'},
      ],
      licenses: [],
      addLvDay: 0,
      addMaDay: 0,
      license: null,
      licenseFields: [
        {key: 'level', label: '코드'},
        {key: 'prdName', label: '라이센스 명', tdClass: 'text-nowrap'},
        {key: 'lvEndDt', label: '기간', formatter: v=>{ return v? this.toYmd(v):''}},
        {key: 'maEndDt', label: 'MA기간', formatter: v=>{ return v? this.toYmd(v):''}},
        {key: 'createdAt', label: '등록일', formatter: v=>{ return v? this.toYmd(v):''}},
      ],
      payment: null,
      payments: [],
      paymentFields: [
        {key: 'Moid',      label: '주문번호', formatter: v=>{return v?v.slice(-6).toUpperCase():'N/A'}},
        {key: '_id',      label: '결제번호', formatter: v=>{return v?v.slice(-6).toUpperCase():'N/A'}},
        {key: 'PayMethod', label: '지불수단' },
        {key: 'fn_name',   label: '카드사' },
        {key: 'CardNum',   label: '카드번호' },
        {key: 'CardQuota', label: '할부' },
        {key: 'Amt',       label: '거래금액', formatter: v=> {return commify(v)} },
        {key: 'cancelYn', label: '취소여부' },
        // {key: 'TID',       label: '거래번호' },
        {key: 'AuthDate',  label: '거래일시' },
        {key: 'AuthCode',  label: '승인번호' },
        {key: 'ResultMsg', label: '거래결과' },
        {key: 'cancelDt', label: '취소일시', formatter: v=>{ return v? this.toYmdHm(v):''} }
      ],
      isCancelProc: false
    }

  },
  async created() {
    try {
      // this.paramMap['dateGbn'] = this.dateGbn;
      this.paramMap['toDate'] = this.toDate;
      this.paramMap['fromDate'] = this.fromDate;
      await this.getPrdCode();
      await this.getSalesOrderList();
    } catch (err) {
      console.log(err);
    }
  },
  computed: {},

  mounted() {
    console.log("mounted---------------------ProductToken");
    // below is not work!
  },

  methods: {
    toYmdHm(v){
      return v?moment(v).format('YY/MM/DD HH:mm'):'';
    },
    toYmd(v){
      return v?moment(v).format('YY/MM/DD'):'';
    },
    toComma(v){
      return v? commify(v):0;
    },
    toNumber(v){
      return v? numberfy(v):0
    },

    async licenseConfirm(prd){
      console.log('licenseConfirm ---> order, prd --->', this.order, prd);
      try {
        if( this.order.cancelYn==='Y'){
          await alertWarn(this.$bvModal, {text: "취소된 주문은 승인할 수 없습니다."});
          return;
        }

        let param = {
          salesOrder: this.order,
          product: prd
        };

        const r = await apiCall('post', `/admin/api/sales/license/confirm`, param );
        if( r.code===200 ){
          await toastSync(this.$bvToast, `${this.order.userId} 주문건 ${prd.prd_name} 라이센스 생성 완료`, 'primary');
          this.order.products = r.result.products;
          await this.getUserLicenses();
        }else{
          await toastSync(this.$bvToast, `${this.order.userId} 주문건 ${prd.prd_name} 라이센스 생성 실패`, 'danger');
        }
      } catch (err) {
        console.log(err);
      }
    },
    async licenseCancel(prd){
      console.log('licenseConfirm --->', this.order, prd);
      try {

        let param = {
          salesOrder: this.order,
          product: prd
        };

        const r = await apiCall('post', `/admin/api/sales/license/cancel`, param );
        if( r.code===200 ){
          await toastSync(this.$bvToast, `${this.order.userId} 주문건 ${prd.prd_name} 라이센스 삭제 완료`, 'primary');
          this.order.products = r.result.products;
          await this.getUserLicenses();
        }else{
          await toastSync(this.$bvToast, `${this.order.userId} 주문건 ${prd.prd_name} 라이센스 삭제 실패`, 'danger');
        }
      } catch (err) {
        console.log(err);
      }
    },
    async licenseEmail(prd){
      console.log('licenseConfirm --->', this.order, prd);
      try {
        let param = {
          salesOrder: this.order,
          product: prd
        };
        this.isSending = true;

        const r = await apiCall('post', `/admin/api/sales/license/send-mail`, param );
        await sleep(1000);

        if( r.code===200 ){
          await toastSync(this.$bvToast, `${this.order.userId}의 ${prd.prd_name} 라이센스 전송 완료`, 'primary');
        }else{
          await toastSync(this.$bvToast, `${this.order.userId}의 ${prd.prd_name} 라이센스 전송 실패`, 'danger');
        }
      } catch (err) {
        console.error(err);
      }finally{
        this.isSending = false;
      }
    },
    async setUserLicense(cmd){
      if(!this.license) {
        alert('선택된 라이센스 정보 없음');
        return;
      }

      let license = this.license;
      console.log( 'setUserLicense --->', this.license, cmd);

      try {
        if (!(await alertConfirm(this.$bvModal, `${license.level} 라이센스 ${cmd}, 진행 하시겠습니까?`, '사용자 라이센스 설정 확인'))) {
          return;
        }
        license.lvEndDt = moment(license.lvEnd).endOf('day').toISOString();
        license.maEndDt = moment(license.maEnd).endOf('day').toISOString();

        const r = await apiCall('post', `/admin/api/sales/set-license/${cmd}`, license );
        if( r.code===200 ){
          await toastSync(this.$bvToast, `${license.level} 라이센스 설정 완료`, 'primary');
          await this.getUserLicenses();
        }else{
          await toastSync(this.$bvToast, `${license.level} 라이센스 설정 실패`, 'danger');
        }
      } catch (err) {
        console.log(err);
      }


    },

    async getUserLicenses(){
      try {

        const {userId} = this.order;

        this.licenses = [];
        const r = await apiCall('get', `/admin/api/sales/license/${userId}`);
        if( r.code===200 ){
          this.licenses = r.result;
          await toastSync(this.$bvToast, `${userId} 라이센스 조회 완료`, 'primary');
        }

      } catch (err) {
        console.log(err);
      }
    },

    async getPaymentList(){
      try {
        this.payments = [];
        const soId = this.order._id;
        const r = await apiCall('get', `/admin/api/sales/payment/${soId}`);
        if( r.code===200 ){
          this.payments = r.result;
          this.payment = null;
          await toastSync(this.$bvToast, `결제내역 조회 완료`, 'info');
        }else{
          await toastSync(this.$bvToast, `${this.order.userId} 결제내역 조회 실패`, 'danger');
        }
      } catch (err) {
        console.log(err);
      }
    },
    async licenseSelected(item){
      console.log("------------ licenseSelected ------------");
      if( item.length === 0 ) {
        this.license = null;
      }else{
        let r = item[0]
        r['lvEnd'] = moment(r.lvEndDt).endOf('day').format('YYYY-MM-DD');
        r['maEnd'] = moment(r.maEndDt).endOf('day').format('YYYY-MM-DD');
        this.addLvDay = 0;
        this.addMaDay = 0;
        this.license = r;
      }
    },
    diffDay(){
      let dt1 = moment(this.dt1);
      let dt2 = moment(this.dt2);
      this.diffDt = dt1.diff(dt2, 'days');
    },
    addDays(gbn) {

      if (gbn === 'lv') {
        this.license.lvEnd = moment(this.license.lvEndDt)
            .add(this.addLvDay, 'days')
            .format('YYYY-MM-DD');

      } else {
        this.license.maEnd = moment(this.license.maEndDt)
            .add(this.addMaDay, 'days')
            .format('YYYY-MM-DD');
      }

      console.log( 'addDays() ---> lvEnd ---', this.license.lvEnd );
      console.log( 'addDays() ---> maEnd ---', this.license.maEnd );
    },


    paymentSelected(item){
      console.log("------------ paymentSelected ------------");
      if( item.length === 0 ) {
        this.payment = null;
      }else{
        this.payment = item[0];
        this.payment['cancelMSG'] = '구매자 요청 ';
      }
    },

    async paymentCancel(){
      try {
        if(!this.payment){
          return alert("결제 항목을 먼저 선택하세요");
        }

        let mesg = '';
        const payId = this.payment._id;
        if(this.payment.cancelYn==='Y') mesg += '[취소 완료건]';
        mesg += `결제번호 '${payId.slice(-6).toUpperCase()}'를 취소 합니다. 진행 하시겠습니까?`;

        if (!(await alertConfirm(this.$bvModal, mesg, '결제 취소'))) {
          return;
        }

        this.isCancelProc = true;

        const r = await apiCall('post', `/admin/api/payment/sales/cancel/${payId}`, this.payment);
        if( r.code===200 ){
          await this.getPaymentList();
          await toastSync(this.$bvToast, `${this.order._id} 결제 취소 성공`, 'info');
        }else{
          await toastSync(this.$bvToast, `${this.order._id} 결제 취소 실패`, 'danger');
        }
      } catch (err) {
        console.log(err);
      }finally{
        this.isCancelProc = false;
      }

    },

    async getPrdCode() {
      console.log("getPrdCode ---- 1");
      this.prdCodeOpts = {};
      this.prdOpts = [];
      this.prdOpts.push({value: null, text: '주문상품 선택'});
      try {
        const r = await apiCall('get', `/admin/api/product/info`);

        r.result.map(i => {
          this.prdCodeOpts[i.prd_code] = i.disp_name;
          this.prdInfo[i.prd_code] = i;
          this.prdOpts.push({value: i, text: i.prd_code + ' | ' + i.disp_name, disabled: (i.use_yn !== "Y")});
        });
        console.log("prdCodeOpts ----> ", this.prdCodeOpts);
        console.log("prdOpts ----> ", this.prdOpts);
        console.log("prdInfo ----> ", this.prdInfo);

      } catch (err) {
        console.log(err);
      }
    },

    async getSalesOrderList() {
      this.paramMap = [];
      this.$refs['orderGrid'].clearFilter();
      let r = null;
      let qry = '';
      this.paramMap['fromDate'] = this.fromDate;
      this.paramMap['toDate'] = this.toDate;
      if (this.searchWord) this.paramMap[this.searchField] = this.searchWord;
      qry = qs.stringify(this.paramMap);
      console.log("query-string ---------- ", qry);
      try {
        this.rows = [];
        r = await apiCall('get', `/admin/api/sales/order?${qry}`);
        // console.log( "getSalesOrderList ----------> ", r);
        if (r.result) {
          this.rows = r.result;
          await toastSync(this.$bvToast, `${this.rows.length}건 조회됨`, 'primary');
        } else {
          await toastSync(this.$bvToast, `에러: ${r.code} ${r.message}`, 'danger');
        }

      } catch (err) {
        console.log(err);
      }

    },
    setDateGbn() {
      // this.paramMap['dateGbn'] = this.dateGbn;
      // this.getSalesOrderList();
    },

    exportExcel() {
      const format = 'xlsx';
      const exportSelectedOnly = true;
      const filename = 'product-order-' + moment().format('YYYY-MM-DD');
      this.$refs["orderGrid"].exportTable(format, exportSelectedOnly, filename);
    },


    async selectSalesOrder(idx, evt) {

      if (!evt){
        this.order = null;
        this.licenses = [];
        this.payment = null;
        return;
      }
      if( !this.$refs['orderGrid'].getSelectedRecords().length ) this.order = null;

      this.order = this.$refs['orderGrid'].getSelectedRecords()[0];
      await this.getUserLicenses();
      await this.getPaymentList();

    },

    /**
     *  주문승인 ***
     * */
    async approvalSalesOrder() {
      let params = this.$refs['orderGrid'].getSelectedRecords();
      this.progCount = 0;
      this.totalCount = params.length;
      if (!params.length) {
        await alertWarn(this.$bvModal, {text: "선택한 레코드가 없습니다."});
        return;
      }
      if (!(await alertConfirm(this.$bvModal, `${params.length} 개의 주문을 승인 합니다. 진행 하시겠습니까?`, '주문 승인'))) {
        return;
      }

      let failCnt = 0;
      for (const so of params) {
        console.log("approvalSalesOrder order --------->", so);
        let payType = '0000';
        let taxStat = '03'; // 03:개인, 01:사업자  04:현금 영수증 대상
        if (so.taxStat && so.taxStat !== '99') {
          taxStat = so.taxStat;
        }

        if (so.approveYn === 'N') {

          if (so.payStat === '99') {
            if (!(await alertConfirm(this.$bvModal, `미결제 주문(${so._id.slice(-6).toUpperCase()})을 승인 하시겠습니까? 승인시 후불로 변경되며, 청구 계산서를 발행 대상입니다..`, '미결제 주문승인'))) {
              failCnt++;
              continue;
            }
            payType = '2000'; // 후불
          }

          if(!so.userId){
            failCnt++;
            await toastSync(this.$bvToast, `${so._id.slice(-6).toUpperCase()} 승인 실패: 주문자 아이디 없음`, 'danger');
            continue;
          }

          if (so.compNo && so.taxStat && so.taxStat === '99') {
            const compNo = so.compNo.replace(/[^0-9]/g, "");
            if (checkBisNo(compNo)) {
              if (payType === '2000') taxStat = '02'; //청구계산서 발행대상
              else if (payType === '1000') taxStat = '01'; // 영수계산서 발행대상
            }
          }
          so.taxStat = taxStat;

          const r = await apiCall('POST', `/admin/api/sales/appr-sales-order`, so);
          console.log("approvalSalesOrder----result--->", r);
          if (r.result) {
            this.progCount++;
            so.payType = payType;
            so.taxStat = taxStat;
            so.approveYn = 'Y';
            so.approveDt = moment().format("YYYY-MM-DD HH:mm:ss");
          }
        } else {
          failCnt++;
          await toastSync(this.$bvToast, `${so._id.slice(-6).toUpperCase()} 승인 실패: 기 승인 주문`, 'danger');
          console.log("====> 기승인 주문: ", so);
        }
      }
      await alertSync(this.$bvModal, {text: `승인: ${this.progCount}건, 실패: ${failCnt}`});
    },

    /**
     *  인증서 전송 ***
     * */
    async sendDocument() {
      let params = this.$refs['orderGrid'].getSelectedRecords();
      this.progCount = 0;
      this.totalCount = params.length;
      if (!params.length) {
        await alertWarn(this.$bvModal, "선택한 레코드가 없습니다.", "레코드를 선택 하세요");
        return;
      }
      if (!(await alertConfirm(this.$bvModal, `${params.length} 개의 주문건에 대한 인증서를 전송합니다. 진행하시겠습니까?`, '인증서 전송'))) {
        return;
      }

      let failCnt = 0;
      for (let order of params) {
        console.log("Send Cert Document order --------->", order);


        const r = await apiCall('POST', `/admin/api/sales/send-license-document`, order);

        console.log("sendDocument----result--->", r);
        if (r.code === 200) {
          this.progCount++;
          order.send_yn = 'Y';
          order.send_stat = '00';
          order.send_dt = moment().format("YYYY-MM-DD HH:mm:ss");
        } else {
          console.log("====> 전송실패: ", r);
          order.send_stat = '90';
          failCnt++;
        }
      }

      await alertSync(this.$bvModal, {text: `전송: ${this.progCount}건, 실패: ${failCnt}`});
    },


    async createSalesOrder() {
      if (!this.selectedPrd) {
        return alert('주문 상품을 선택하세요');
      }


      let r = null;
      const rec = cloneVar( _sales_order );
      // this.$refs.orderGrid.insertSalesOrder( rec );
      // this.rows.push( rec );
      const orderPrd = {
        prd_code : this.selectedPrd.prd_code,
        prd_name : this.selectedPrd.prd_name,
        disp_name: this.selectedPrd.disp_name,
        warranty_mon : this.selectedPrd.warranty_mon,
        unit_price : this.selectedPrd.unit_price,
        qty : 1,
        confirmYn: 'N',
        userLicense: null,
      }

      // rec.pay_type = '0000';
      rec.products.push( orderPrd );
      rec.amount = orderPrd.unit_price * orderPrd.qty;

      rec.ord_dt = moment().format("YYYY-MM-DD HH:mm");
      try {
        r = await apiCall('POST', `/admin/api/sales/order`, rec);
        if ( r.code===200 ) {
          await toastSync(this.$bvToast, `주문이 등록 되었습니다. 주문번호: ${r.result._id.slice(-6).toUpperCase()}`);
          await this.getSalesOrderList();
        }
      } catch (err) {
        console.log(err);
      }
    },

    validateCell(val, oldVal, rec, field) {
      console.log("----------------validateCell-------------------");
      console.log("validateCell---value ---> ", val);
      console.log("validateCell---oldVal ---> ", oldVal);
      console.log("validateCell---rec ---> ", rec);
      console.log("validateCell---field ---> ", field);
      // console.log( "validateCell---",this.$refs['orderGrid'] );
      // console.log( "validateCell---",this.$refs['orderGrid'].selectRecord(0) );
    },

    async updateSalesOrder(rec) {
      let rc = rec[0];
      if (!rc.keys[0]) {
        console.log("nokey");
        return;
      }
      const tIndex = this.$refs['orderGrid'].rowIndex[rc.$id];
      console.log("updateSalesOrder--------- rec[0]|rc --->", rc);
      console.log("updateSalesOrder--------- rec[0].$id--->", rc.$id);
      console.log("updateSalesOrder--------- rec[0].$id--->", this.$refs['orderGrid']);
      console.log("updateSalesOrder--------- rowIndex--->", tIndex);
      let row = this.$refs['orderGrid'].table[tIndex];

      console.log("row ---------> ", row);

      let r = null;

      try {
        let param = {};
        const fieldName = rc.name;
        let newVal = rc.newVal;

        if (row.approveYn === 'Y') {
          if (!(await alertConfirm(this.$bvModal, "승인된 주문을 수정합니다. 계속하시겠습니까?", '승인 주문 수정'))) {
            row[rc.name] = rc.oldVal;
            return;
          }
        }

        if (fieldName === "compNo") {
          const compNo = newVal.replace(/[^0-9]/g, '');
          console.log(compNo);
          if (!checkBisNo(compNo)) {
            await toastSync(this.$bvToast, `사업자번호 검증 실패: ${compNo}`);
          }
          newVal = compNo;
          row.compNo = compNo;
        } else if (fieldName === 'payType') {
          if (newVal !== '9999' && newVal !== '0000') {
            row.payAmount = row.price;
            row.payStat = '00'; // 결제완료
            row.payDt = moment().toISOString();
            param['payAmount'] = row.payAmount;
            param['payStat'] = row.payStat;
            param['payDt'] = row.payDt;
          }
        }

        param[fieldName] = newVal;

        r = await apiCall("PUT", `/admin/api/sales/order/${row._id}`, param);
        if( r.code===200){
          await toastSync(this.$bvToast, `수정 되었습니다.`, 'success');
        }else{
          await toastSync(this.$bvToast, `수정 실패`, 'danger');
        }
        console.log(r);

      } catch (err) {
        console.log(err);
      }
    },

    async deleteSalesOrder(recs) {
      // let r = null, msg = null, notice = null;

      try {
        console.log(recs);
        const params = this.$refs['orderGrid'].getSelectedRecords();
        this.progCount = 0;
        this.totalCount = params.length;
        console.log("deleteSalesOrder ----> ", params);
        console.log("deleteSalesOrder length ----> ", params.length);

        const confirmMsg = `${params.length} 개의 데이터를 삭제 합니다. 삭제된 데이터는 복구할수 없습니다. 진행 하시겠습니까?`;

        if (!(await alertConfirm(this.$bvModal, confirmMsg, '주문정보 삭제'))) {
          return;
        }

        // this.$refs['orderGrid'].deleteSelectedRecords();
        let failCnt = 0;
        for (let order of params) {
          console.log("deleteSalesOrder for --------->", order);
          if (order.approveYn !== 'Y') {
            const tIndex = this.$refs['orderGrid'].rowIndex[order.$id];
            const r = await apiCall('DEL', `/admin/api/sales/order/${order._id}`);
            console.log(r);
            if (r.code===200) {
              this.progCount++;
              this.$refs["orderGrid"].deleteRecord(tIndex);
            }
          } else {
            failCnt++;
            await toastSync(this.$bvToast, `${order._id.slice(-6).toUpperCase()} 삭제 실패: 기 승인 주문`, 'danger');
          }
        }

        await alertSync(this.$bvModal, {text: `삭제: ${this.progCount}건, 실패: ${failCnt}`});
        this.$refs['orderGrid'].clearAllSelected();
        await this.getSalesOrderList();
        this.order = null;
        this.licenses = [];
        this.payment = null;

      } catch (err) {

        console.log(err);
      }
    },

  }
}
</script>
